@font-face {
  font-family: baucher-gothic-urw-extended, sans-serif;
  font-weight: 100;
  font-display: swap; }
@font-face {
  font-family: 'Christopherhand';
  src: url("/css/script-font.ttf") format("truetype");
  font-display: swap; }
html, body {
  max-width: 100%;
  overflow-x: hidden; }

.mw100 {
  max-width: 100%; }

body {
  color: #000;
  font-family: "Open sans", sans-serif !important; }
  body a {
    color: #dc493b; }
    body a:hover {
      color: #ff6f61; }

.alternative-logo {
  width: 160px !important; }

@media (max-width: 767px) {
  .alternative-logo {
    margin-top: 4px;
    width: 19vw !important; } }
.hidden-menu {
  background-color: #7bacb4 !important;
  color: #7bacb4 !important; }
  .hidden-menu a {
    color: #7bacb4 !important; }
  .hidden-menu li {
    border-color: #7bacb4 !important; }

.btn {
  -webkit-transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
  -moz-transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1); }

.smaller_font {
  font-size: 1.6vw !important; }

.btn-link {
  font-weight: 400;
  color: #dc493b;
  border-radius: 0; }

.white-link a, .footer a {
  color: #fff !important; }
  .white-link a:hover, .footer a:hover {
    color: #ffcfca !important; }

.navbar {
  overflow: visible;
  height: 115px;
  border: 0;
  box-shadow: none;
  background-color: #7bacb4; }
  .navbar :before {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 92%); }
  .navbar .twitter-typeahead input {
    background-color: #9bc5cb !important;
    color: white; }
  .navbar .typeahead {
    text-align: left; }
  .navbar .navbar-collapse {
    margin-top: 10px !important; }
  .navbar .navbar-nav {
    margin-top: 25px; }
    .navbar .navbar-nav li {
      font-family: baucher-gothic-urw-extended, sans-serif;
      font-size: 20pt; }
      .navbar .navbar-nav li a {
        color: #fff;
        letter-spacing: 1px;
        font-size: 2.2vw;
        font-weight: 100; }
        .navbar .navbar-nav li a:hover {
          color: #eff5f6; }
  .navbar .info-phone {
    margin-top: 25px; }
    .navbar .info-phone span {
      margin-top: 16px; }
    .navbar .info-phone a {
      color: #000;
      font-size: 10pt; }
      .navbar .info-phone a i {
        font-size: 14pt !important; }

.info-phone-mobile {
  position: fixed !important;
  right: 80px !important;
  top: 7px !important;
  margin-top: 0px !important; }
  .info-phone-mobile span {
    display: block !important;
    margin-top: 8px !important; }

.top-right {
  position: absolute;
  top: -25px;
  right: 15px; }
  .top-right a {
    font-size: 12pt !important;
    font-family: "Open sans", sans-serif; }
  .top-right .dropdown-menu {
    background-color: #bdd5d9; }

.navbar-inverse {
  padding: 0; }

.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a:hover {
  background-color: #7bacb4 !important;
  color: #fff; }

.navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover {
  color: #7bacb4; }

.action {
  background-color: #7bacb4; }
  .action span {
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 12pt;
    text-align: left; }

.btn-yellow {
  background-color: #ffd800;
  font-family: 'Open Sans', sans-serif;
  padding: 7px 40px;
  border-radius: 0;
  font-size: 18pt;
  color: #fff;
  font-weight: bold; }

.btn-black {
  background-color: #000;
  font-family: 'Open Sans', sans-serif;
  border-radius: 0;
  font-size: 10pt;
  color: #fff;
  font-weight: bold; }
  .btn-black:hover {
    color: #f6f6f6; }

.btn-orange {
  background-color: #ffcfca;
  font-family: 'Open Sans', sans-serif;
  border-radius: 0;
  font-size: 10pt;
  color: #ff6f61;
  border-color: #ffcfca;
  font-weight: bold; }
  .btn-orange:hover {
    background-color: #ff6f61;
    color: #fff; }

.btn-green {
  background-color: #81b2b8;
  font-family: 'Open Sans', sans-serif;
  border-radius: 0;
  font-size: 10pt;
  color: #fff;
  border: 1px solid #81b2b8; }
  .btn-green:hover {
    background-color: #fff;
    color: #81b2b8;
    border: 1px solid #81b2b8; }

.btn-white-bg {
  width: 100%;
  background-color: #fff !important; }

.search-field-green {
  background-color: #9bc5cb;
  color: #fff !important;
  border-radius: 0;
  padding: 10px;
  height: 50px; }

h1 {
  color: #ff6f61;
  font-family: baucher-gothic-urw-extended, sans-serif;
  font-weight: 100;
  font-size: 50pt; }

.header {
  color: #ff6f61 !important;
  font-family: baucher-gothic-urw-extended, sans-serif;
  font-weight: 100;
  font-size: 50pt; }

.btn-fw {
  margin: 4px;
  width: 100%; }

.btn-white-red {
  width: 100%;
  background-color: transparent;
  border-radius: 0;
  color: #ff6f61;
  border-color: #ff6f61;
  font-weight: normal; }
  .btn-white-red:hover {
    background-color: #ff6f61;
    color: #fff; }

@media (max-width: 767px) {
  .navbar-header {
    margin-top: -15px; }

  .filterForm {
    min-height: 250px !important; }

  .thumb-box {
    display: none; } }
.btn-white-red-bg {
  width: 100%;
  background-color: #fff;
  border-radius: 0;
  color: #ff6f61;
  border-color: #ff6f61;
  font-weight: normal; }
  .btn-white-red-bg:hover {
    background-color: #ff6f61;
    color: #fff; }

.red {
  color: #ff6f61 !important; }

.title {
  font-family: baucher-gothic-urw-extended, sans-serif;
  font-size: 45pt;
  color: #000 !important;
  font-weight: 100; }

.script, .script-title {
  font-family: "Christopherhand", sans-serif;
  font-size: 35pt; }

.script-title {
  padding-top: 0 !important;
  margin-bottom: 0 !important; }

.script-img {
  margin-top: 30px;
  height: 30px;
  position: absolute;
  left: 70px;
  width: 6vw;
  max-width: 100px; }

#theHomeSearch {
  margin-top: 30px; }

.hero {
  position: relative;
  text-align: center;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5); }
  .hero img {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 87%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 87%);
    width: 100%; }

.thesearchform {
  width: 30%; }

.more-info {
  background-color: #f2f7f7;
  background-size: cover;
  overflow: hidden;
  padding-bottom: 140px !important;
  margin-bottom: -90px;
  position: relative;
  text-align: left;
  z-index: 1;
  color: #000;
  -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 6%, 0 0);
  clip-path: polygon(0 100%, 100% 100%, 100% 6%, 0 0); }

/*
 * Helper classes
*/
.pt-5 {
  padding-top: 25px; }

.pb-5 {
  padding-bottom: 25px; }

.pt-10 {
  padding-top: 50px; }

.pt-1 {
  padding-top: 5px; }

.pt-2 {
  padding-top: 10px; }

.pb-10 {
  padding-bottom: 50px; }

.big-input {
  font-size: 20pt !important;
  padding: 20px;
  height: auto !important;
  border-radius: 0; }

#content-prefix {
  margin-top: 40px; }

.blue-container {
  border: 1px solid #7bacb4;
  border-right: none;
  color: #7bacb4; }

.mt-5 {
  margin-top: 50px; }

.mt-10 {
  margin-top: 100px; }

.mt-15 {
  margin-top: 150px; }

.mt-3 {
  margin-top: 30px; }

/*
* end of helpers
 */
.footer h1, .footer h2, .footer h3, .footer h4, .footer h5 {
  font-family: baucher-gothic-urw-extended, sans-serif;
  font-weight: normal;
  color: #fff; }
.footer h4 {
  font-size: 39pt; }
.footer h5 {
  font-size: 24pt; }
.footer a {
  text-decoration: none !important; }
.footer li {
  display: block; }
.footer .list li {
  display: inline-block; }

.overlay-box {
  background-color: transparent;
  height: auto;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  padding: 15px;
  transition: opacity .5s; }

.object {
  overflow: hidden;
  margin-bottom: 0 !important; }

.objects .object ul.facilities li i.fa, .page .content ul.facilities li i.fa {
  color: #000; }

.objects {
  margin-bottom: 0px !important; }

.object_footer {
  background-color: #eff5f6;
  margin-bottom: 15px;
  min-height: 147px;
  position: relative; }
  .object_footer .feature-icon {
    color: #000;
    font-size: 10pt; }
  .object_footer .row {
    padding-top: 5px;
    padding-bottom: 5px; }
  .object_footer .button-row {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: auto; }
  .object_footer .icons {
    min-height: 50px; }

.color-black {
  color: #000 !important; }

.more-info-button {
  width: 100%;
  background-color: transparent;
  border-radius: 0;
  color: #ff6f61;
  border-color: #ff6f61;
  font-weight: bold; }
  .more-info-button:hover {
    background-color: #ff6f61;
    color: #fff; }

.page .info {
  color: #000; }

.page .share span {
  color: #000; }

.page h2 {
  font-family: baucher-gothic-urw-extended, sans-serif;
  font-weight: 100;
  font-size: 40pt;
  color: #ff6f61; }

h2 {
  font-family: baucher-gothic-urw-extended, sans-serif;
  font-weight: 100;
  font-size: 40pt;
  color: #ff6f61; }

.object-info-button {
  background-color: transparent;
  border-radius: 0;
  color: #ff6f61;
  border-color: #ff6f61;
  font-weight: bold; }
  .object-info-button:hover {
    background-color: #ff6f61 !important;
    color: #fff; }

.object-item {
  min-height: 350px; }

.home-search {
  min-height: 340px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 89%);
  width: 100%;
  margin-top: 55px;
  -webkit-filter: brightness(280%);
  filter: brightness(280%); }

.map-container {
  margin-top: -80px; }

.tt-menu {
  position: absolute !important;
  z-index: 9999999 !important;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  padding: 5px 0;
  margin: -2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left; }

@media (max-width: 767px) {
  .tt-menu {
    position: fixed !important;
    top: 120px !important;
    left: 0px;
    display: block;
    min-width: 100%; } }
.spotlight {
  background-color: #7bacb4;
  background-size: cover;
  color: #fff;
  overflow: hidden;
  position: relative;
  text-align: center;
  z-index: 1;
  -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 6%, 0 0);
  clip-path: polygon(0 100%, 100% 100%, 100% 6%, 0 0); }
  .spotlight .padding {
    padding-bottom: 10px !important; }
  .spotlight .btn-black {
    left: 10px !important; }

.navbar .info span {
  color: #000;
  font-weight: bold;
  font-size: 10px; }

.info-phone a i {
  color: #ffd800 !important;
  border-color: #ffd800 !important;
  font-size: 22pt !important;
  width: 40px !important;
  height: 40px !important;
  line-height: 33px !important;
  border-width: 4px !important; }

.sub {
  background-color: transparent;
  color: #7bacb4 !important;
  z-index: 9;
  margin-top: 15px; }
  .sub .breadcrumb {
    margin-top: -10px;
    margin-bottom: -10px;
    color: #7bacb4 !important; }
    .sub .breadcrumb li:after {
      padding-left: 10px;
      content: '\f105';
      font-family: 'FontAwesome'; }
    .sub .breadcrumb li:last-child:after {
      content: ''; }
    .sub .breadcrumb li:before {
      content: '' !important; }
    .sub .breadcrumb a {
      color: #7bacb4 !important; }

.black-title {
  color: #000; }

#tour_request_modal label {
  font-weight: normal; }

.footer-text-info {
  font-weight: bold; }

.modal-body {
  margin-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px; }

@media (max-width: 768px) {
  .modal-body {
    margin-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px; } }
.form-control {
  background-color: #f3f7f8;
  border-radius: 0;
  color: #000; }
  .form-control ::-webkit-input-placeholder {
    /* Edge */
    color: #8d8b8c; }
  .form-control :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #8d8b8c; }
  .form-control ::placeholder {
    color: #8d8b8c; }

.close {
  font-size: 34px;
  opacity: .8;
  margin-right: 10px; }
  .close .text {
    font-size: 21px;
    font-weight: normal;
    text-transform: lowercase; }

.filterForm {
  background-color: #e4eaed;
  margin: 0px 0;
  margin-right: -30px; }
  .filterForm .filter-dropdown {
    color: #000;
    padding: 15px; }
    .filterForm .filter-dropdown span {
      color: #000;
      font-size: 10pt; }
  .filterForm .filter-dropdown:hover {
    background-color: #ff6f61; }

.toggle-option {
  z-index: 999999;
  margin-top: -8px;
  color: white; }
  .toggle-option a {
    color: white; }
    .toggle-option a:hover {
      color: white; }
  .toggle-option .toggleImage {
    margin-left: 10px;
    height: 25px; }

.object-view-title {
  position: relative;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  background: #000;
  font-family: 'Open Sans', sans-serif;
  font-size: 20pt;
  font-weight: bold;
  padding: 16px 12px;
  color: #fff; }
  .object-view-title:before {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 100%;
    bottom: 0;
    width: 100vw;
    height: 100%;
    content: "";
    background: #000; }

.search-button-item {
  height: 50px !important; }
  .search-button-item i {
    float: none !important;
    color: #fff !important;
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
    line-height: normal !important;
    width: auto !important;
    height: auto !important; }

.owl-prev, .owl-next {
  color: #fff !important; }

.page .blogitem .title .date {
  float: left;
  margin-right: 15px;
  text-align: center;
  display: inline-block;
  background: #dc493b;
  font-weight: normal;
  padding: 8px 15px;
  color: #fff;
  line-height: 30pt;
  font-size: 30pt; }

.page .blogitem .title .date span {
  display: block;
  font-size: 40pt; }

.page .category h3 {
  padding-top: 0px;
  margin: 0px;
  color: #dc493b;
  padding-bottom: 15px;
  font-family: baucher-gothic-urw-extended, sans-serif;
  font-weight: 100;
  font-size: 35pt; }

.page .category ul li a span {
  text-decoration: underline;
  color: #7bacb4; }

.page .request {
  background: #bdd5d9; }
  .page .request h3 {
    color: #fff; }

.page .appointment {
  color: #000 !important;
  border: 1px solid #dc493b !important; }
  .page .appointment input {
    background-color: #f6f6f6; }

.page .request h3, .page .appointment h3 {
  color: #000; }

.navbar-toggle {
  background-color: #81b2b8 !important;
  border-color: #bdd5d9 !important; }

@media (max-width: 1400px) {
  .navbar .info span {
    display: none; }

  .navbar-brand {
    margin-right: 0px !important; } }
@media (max-width: 1600px) {
  .smaller_font {
    font-size: 18pt !important; }

  .nav > li > a {
    padding: 10px 8px; } }
@media (max-width: 1400px) {
  .smaller_font {
    font-size: 18pt !important; }

  .nav > li > a {
    padding: 10px 5px; }

  .thesearchform {
    width: 20%; } }
.btn-black {
  max-width: 100%; }

.objects .object ul.facilities li, .page .content ul.facilities li {
  font-size: inherit; }

.page .request .form-control {
  border-radius: 0; }

.page .appointment input {
  border: 0;
  border-radius: 0; }

.slider-icon {
  opacity: 1;
  color: #fff;
  font-size: 60pt !important;
  margin-top: -45px !important; }

.owl-item .btn-black {
  position: absolute;
  left: 6px; }

.owl-theme .owl-controls .owl-buttons div {
  opacity: 1; }

.footer-contact-text {
  line-height: 1.1;
  padding-top: 40px; }

.the-logo {
  width: 14vw !important;
  margin-top: 1vw; }

.navbar .info {
  margin-left: 0 !important; }

@media (min-width: 380px) and (max-width: 767px) {
  .navbar {
    height: 101px; }

  span.twitter-typeahead {
    width: 102%; } }
@media (max-width: 400px) {
  .navbar {
    height: 106px !important; } }
@media (max-width: 767px) {
  .spotlight #carousel .owl-controls .owl-prev {
    left: -9% !important; }

  .spotlight #carousel .owl-controls .owl-next {
    right: -9% !important; }

  .spotlight #carousel .owl-controls .fa {
    color: #ff6f61; }

  .script-img {
    left: 38px;
    width: 8vw; }

  .navbar-toggle {
    margin-top: 25px; }

  .main-menu {
    background-color: #bdd5d9;
    padding-right: 10vw;
    padding-left: 10vw;
    margin-top: 0px !important;
    width: 100%;
    position: absolute; }
    .main-menu li {
      border-top: 1px solid #d7e6e8; }
      .main-menu li a {
        font-size: 14pt !important;
        text-align: center;
        font-weight: bold;
        padding: 10px 5px;
        font-family: "Open sans", sans-serif !important; }

  .sub .breadcrumb li:after {
    color: #dc493b; }

  .breadcrumb {
    margin-left: -15px;
    margin-right: -20px; }
    .breadcrumb li:first-child {
      display: none; }
    .breadcrumb li:before {
      padding: 0 1px !important; }
    .breadcrumb li:after {
      padding-left: 5px !important; }

  .the-button-footer {
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 0;
    height: 57px;
    padding: 5px 21px;
    z-index: 9;
    background-color: #efefef;
    box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.29); }
    .the-button-footer .the-button {
      width: calc(50% - 5px);
      font-size: 1.2rem;
      height: 48px;
      line-height: 8px;
      margin-top: 0;
      padding: 0;
      float: left; }
    .the-button-footer button {
      border: 0;
      font-size: 10pt !important; }
    .the-button-footer .button-plan {
      line-height: 8px;
      margin-top: 0;
      padding: 0;
      width: 95%;
      height: 44px;
      background: #FFD800;
      float: left;
      color: #fff; }
    .the-button-footer .button-more-info {
      float: right;
      color: #dc493b;
      line-height: 8px;
      margin-top: 0;
      box-shadow: 0px 0px 0px transparent;
      padding: 0;
      width: 95%;
      background: #fff;
      height: 44px; }

  .filter-items-row {
    min-height: 650px; }

  .thesearchform {
    width: 108%;
    margin-left: auto;
    margin-right: auto;
    max-width: 108% !important;
    margin-top: 21px !important; }

  .nopadding {
    padding: 10px !important; }

  .the-logo {
    width: 30vw !important; }

  .background-image-home {
    margin-top: -20px !important; }

  .sub {
    margin-top: 55px !important; }

  .view-sub {
    margin-top: 35px !important; }

  .mobile-no-pt {
    padding-top: 0 !important; }

  .break-out {
    z-index: 999999999; }

  .language-navbar {
    position: absolute;
    top: -16px;
    right: 94px; }
    .language-navbar .dropdown {
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
      z-index: 99999;
      background-color: #7bacb4; }
      .language-navbar .dropdown a {
        color: #fff !important; }

  #main-nav {
    border-top: 0 !important;
    margin-top: 0 !important;
    box-shadow: none;
    -webkit-box-shadow: none; }

  .big-input {
    font-size: 12pt !important; }

  .btn-white-red.big-input {
    font-size: 16pt !important; }

  .more-info, .spotlight {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 3%, 0 0);
    clip-path: polygon(0 100%, 100% 100%, 100% 3%, 0 0); }

  .home-search {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 95%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 95%); }

  .blue-container {
    border-right: 1px; }

  .footer-contacter .footer-image, .footer-contacter .footer-contact-text {
    padding-bottom: 0;
    margin-bottom: 0; }
  .footer-contacter .footer-image {
    bottom: -7px; }

  .object_footer {
    min-height: 180px; }
    .object_footer .row {
      padding: 5px !important; }
    .object_footer .btn-black {
      margin-left: 5px;
      display: inline !important;
      margin-bottom: 10px; }

  .more-info-container {
    margin-bottom: 5px; } }
.object_footer .btn-black {
  display: none; }

@media (min-width: 768px) {
  .footer-contacter img {
    margin-top: -20px; } }
@media (max-width: 768px) {
  .object-intro h1 {
    font-size: 25pt; }

  .map-container {
    margin-top: 0; }

  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0; }

  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0; } }
.background-image-home {
  margin-top: 70px;
  background: url("/img/header_01.jpg");
  background-size: cover;
  min-height: 300px;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 95%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 95%); }

.show-more-info {
  padding: 0; }

.nopadding {
  padding: 0;
  margin-right: -30px;
  margin-left: -30px; }

.more-info-container2 {
  background-color: #7bacb4;
  padding-top: 100px;
  text-align: center; }
  .more-info-container2 .object-info-button {
    background-color: white;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 10px 60px; }
  .more-info-container2 .zoom {
    padding: 15px;
    background-color: #FFD800;
    transition: transform .2s;
    margin: 0 auto;
    cursor: pointer;
    color: #fff;
    text-align: center;
    font-family: arial;
    font-weight: 600;
    -ms-transform: scale(1);
    /* IE 9 */
    -webkit-transform: scale(1);
    /* Safari 3-8 */
    transform: scale(1);
    transition-duration: 1s;
    border: 0; }
  .more-info-container2 .zoom:hover {
    -ms-transform: scale(1.1);
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari 3-8 */
    transform: scale(1.1);
    cursor: pointer; }
  .more-info-container2 .animation-zoom {
    transition-duration: 2s;
    -ms-transform: scale(1.1);
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari 3-8 */
    transform: scale(1.1); }

.text-white {
  color: #fff !important; }

.call-white-text {
  font-size: 15pt;
  line-height: 20pt; }

a.boxclose {
  float: right;
  cursor: pointer;
  color: #fff;
  border: 1px solid #AEAEAE;
  border-radius: 30px;
  background: #605F61;
  font-size: 29px;
  font-weight: bold;
  display: inline-block;
  line-height: 0px;
  padding: 11px 3px; }

.boxclose:before {
  content: "x"; }

/*# sourceMappingURL=theme.css.map */
