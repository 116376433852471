.specialcheck input[type="checkbox"] {
	display: none;
}

.specialcheck input[type="checkbox"] + label {
	cursor: pointer;
	font-size: 1em;
}

/* ==================================================================== */
/* CHECKBOX TYPE 7 ---------------------------------------------------- */
/* ==================================================================== */


[id^="checkbox-7-"] + label {
	background-color: #FFF;
	padding: 8px 0px 8px 9px;
	border-radius: 5px;
	display: inline-block;
	position: relative;
	margin-right: 10px;
	border:1px solid #c3c3c3;
	width:190px;
	overflow: hidden;
	color: #9d9d9d;
	-webkit-transition: all 0.3s linear, color 0 linear;
	transition: all 0.3s linear, color 0 linear;
}

[id^="checkbox-7-"] + label:after {
	display:none;
}

[id^="checkbox-7-"]:checked + label {
	color: #fff;
}

[id^="checkbox-7-"] + label > span {
	position: relative;
	z-index: 888;
	font-family: 'Open Sans', sans-serif;
}

[id^="checkbox-7-"]:active + label:after {
	box-shadow: 0 0 1px rgba(0, 0, 0, 0.6), inset 0 2px 6px rgba(0, 0, 0, 0.1), inset 0 -18px 15px -10px rgba(0, 0, 0, 0.05);
	color:#fff;
}

[id^="checkbox-7-"]:checked + label:after {
	content: '\2715';
}

[id^="checkbox-7-"] + label:before {
	content: ' ';
	position: absolute;
	background: #0a6287;
	width: 0%;
	height: 100%;
	top: 0;
	-webkit-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	left: 0;
}

[id^="checkbox-7-"]:checked + label:before {
	content: ' ';
	position: absolute;
	width: 100%;
	height: 100%;
	text-shadow: 0 1px 0 rgba(0,0,0,0.1);
	top: 0;
	left: 0;
	color:#fff;
}