@-webkit-keyframes left-right {
    0% {
        -moz-transform: translateY(-20%) translateX(-10%);
        -ms-transform: translateY(-20%) translateX(-10%);
        -webkit-transform: translateY(-20%) translateX(-10%);
        transform: translateY(-20%) translateX(-10%)
    }
    100% {
        -moz-transform: translateY(0%) translateX(10%);
        -ms-transform: translateY(0%) translateX(10%);
        -webkit-transform: translateY(0%) translateX(10%);
        transform: translateY(0%) translateX(10%)
    }
}

@-moz-keyframes left-right {
    0% {
        -moz-transform: translateY(-20%) translateX(-10%);
        -ms-transform: translateY(-20%) translateX(-10%);
        -webkit-transform: translateY(-20%) translateX(-10%);
        transform: translateY(-20%) translateX(-10%)
    }
    100% {
        -moz-transform: translateY(0%) translateX(10%);
        -ms-transform: translateY(0%) translateX(10%);
        -webkit-transform: translateY(0%) translateX(10%);
        transform: translateY(0%) translateX(10%)
    }
}

@-ms-keyframes left-right {
    0% {
        -moz-transform: translateY(-20%) translateX(-10%);
        -ms-transform: translateY(-20%) translateX(-10%);
        -webkit-transform: translateY(-20%) translateX(-10%);
        transform: translateY(-20%) translateX(-10%)
    }
    100% {
        -moz-transform: translateY(0%) translateX(10%);
        -ms-transform: translateY(0%) translateX(10%);
        -webkit-transform: translateY(0%) translateX(10%);
        transform: translateY(0%) translateX(10%)
    }
}

@keyframes left-right {
    0% {
        -moz-transform: translateY(-20%) translateX(-10%);
        -ms-transform: translateY(-20%) translateX(-10%);
        -webkit-transform: translateY(-20%) translateX(-10%);
        transform: translateY(-20%) translateX(-10%)
    }
    100% {
        -moz-transform: translateY(0%) translateX(10%);
        -ms-transform: translateY(0%) translateX(10%);
        -webkit-transform: translateY(0%) translateX(10%);
        transform: translateY(0%) translateX(10%)
    }
}

@-webkit-keyframes right-left {
    0% {
        -moz-transform: translateY(0%) translateX(10%);
        -ms-transform: translateY(0%) translateX(10%);
        -webkit-transform: translateY(0%) translateX(10%);
        transform: translateY(0%) translateX(10%)
    }
    100% {
        -moz-transform: translateY(-20%) translateX(-10%);
        -ms-transform: translateY(-20%) translateX(-10%);
        -webkit-transform: translateY(-20%) translateX(-10%);
        transform: translateY(-20%) translateX(-10%)
    }
}

@-moz-keyframes right-left {
    0% {
        -moz-transform: translateY(0%) translateX(10%);
        -ms-transform: translateY(0%) translateX(10%);
        -webkit-transform: translateY(0%) translateX(10%);
        transform: translateY(0%) translateX(10%)
    }
    100% {
        -moz-transform: translateY(-20%) translateX(-10%);
        -ms-transform: translateY(-20%) translateX(-10%);
        -webkit-transform: translateY(-20%) translateX(-10%);
        transform: translateY(-20%) translateX(-10%)
    }
}

@-ms-keyframes right-left {
    0% {
        -moz-transform: translateY(0%) translateX(10%);
        -ms-transform: translateY(0%) translateX(10%);
        -webkit-transform: translateY(0%) translateX(10%);
        transform: translateY(0%) translateX(10%)
    }
    100% {
        -moz-transform: translateY(-20%) translateX(-10%);
        -ms-transform: translateY(-20%) translateX(-10%);
        -webkit-transform: translateY(-20%) translateX(-10%);
        transform: translateY(-20%) translateX(-10%)
    }
}

@keyframes right-left {
    0% {
        -moz-transform: translateY(0%) translateX(10%);
        -ms-transform: translateY(0%) translateX(10%);
        -webkit-transform: translateY(0%) translateX(10%);
        transform: translateY(0%) translateX(10%)
    }
    100% {
        -moz-transform: translateY(-20%) translateX(-10%);
        -ms-transform: translateY(-20%) translateX(-10%);
        -webkit-transform: translateY(-20%) translateX(-10%);
        transform: translateY(-20%) translateX(-10%)
    }
}

.sy-box.sy-loading {
    background: url("../images/sy-loader.gif") 50% 50% no-repeat;
    -moz-background-size: 32px;
    -o-background-size: 32px;
    -webkit-background-size: 32px;
    background-size: 32px;
    min-height: 40px
}

.sy-box.sy-loading .sy-slides-wrap, .sy-box.sy-loading .sy-pager {
    visibility: hidden
}

.sy-slides-wrap {
    position: relative;
    height: 100%;
    width: 100%
}

.sy-slides-wrap:hover .sy-controls {
    display: block
}

.sy-slides-crop {
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden
}

.sy-list {
    width: 100%;
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute
}

.sy-list.horizontal {
    -moz-transition: left ease;
    -o-transition: left ease;
    -webkit-transition: left ease;
    transition: left ease
}

.sy-list.vertical {
    -moz-transition: top ease;
    -o-transition: top ease;
    -webkit-transition: top ease;
    transition: top ease
}

.sy-slide {
    position: absolute;
    width: 100%;
    z-index: 2
}

.sy-slide.kenburns {
    width: 140%;
    left: -20%
}

.sy-slide.kenburns.useCSS {
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    -webkit-transition-property: opacity;
    transition-property: opacity
}

.sy-slide.kenburns.useCSS.sy-ken:nth-child(1n) {
    -webkit-animation-name: left-right;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-name: left-right;
    -moz-animation-fill-mode: forwards;
    -o-animation-name: left-right;
    -o-animation-fill-mode: forwards;
    animation-name: left-right;
    animation-fill-mode: forwards
}

.sy-slide.kenburns.useCSS.sy-ken:nth-child(2n) {
    -webkit-animation-name: right-left;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-name: right-left;
    -moz-animation-fill-mode: forwards;
    -o-animation-name: right-left;
    -o-animation-fill-mode: forwards;
    animation-name: right-left;
    animation-fill-mode: forwards
}

.sy-slide.sy-active {
    z-index: 3
}

.sy-slide > img {
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    border: 0
}

.sy-slide > a {
    margin: 0;
    padding: 0;
    display: block;
    width: 100%
}

.sy-slide > a > img {
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    border: 0
}

.sy-controls {
    display: none;
    list-style: none;
    height: 100%;
    width: 100%;
    position: absolute;
    padding: 0;
    margin: 0
}

.sy-controls li {
    position: absolute;
    width: 10%;
    min-width: 4.2em;
    height: 100%;
    z-index: 33
}

.sy-controls li.sy-prev {
    left: 0;
    top: 0
}

.sy-controls li.sy-prev a:after {
    background-position: -5% 0
}

.sy-controls li.sy-next {
    right: 0;
    top: 0
}

.sy-controls li.sy-next a:after {
    background-position: 105% 0
}

.sy-controls li a {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    text-indent: -9999px
}

.sy-controls li a:link, .sy-controls li a:visited {
    opacity: 0.4
}

.sy-controls li a:hover, .sy-controls li a:focus {
    opacity: 0.8;
    outline: none
}

.sy-controls li a:after {
    content: "";
    background-image: url("../images/arrows.svg");
    background-repeat: no-repeat;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
    text-align: center;
    text-indent: 0;
    line-height: 2.8em;
    color: #111;
    font-weight: 800;
    position: absolute;
    background-color: #fff;
    width: 2.8em;
    height: 2.8em;
    left: 50%;
    top: 50%;
    margin-top: -1.4em;
    margin-left: -1.4em;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%
}

@media only screen and (max-device-width: 600px) {
    .sy-controls {
        display: block
    }

    .sy-controls li {
        min-width: 2.1em
    }

    .sy-controls li a:after {
        width: 1.4em;
        height: 1.4em;
        margin-top: -0.7em;
        margin-left: -0.7em
    }
}

.sy-caption-wrap {
    position: absolute;
    bottom: 2em;
    z-index: 12;
    left: 50%
}

.sy-caption-wrap .sy-caption {
    position: relative;
    left: -50%;
    background-color: rgba(0, 0, 0, 0.54);
    color: #fff;
    padding: 0.4em 1em;
    -moz-border-radius: 1.2em;
    -webkit-border-radius: 1.2em;
    border-radius: 1.2em
}

.sy-caption-wrap .sy-caption a:link, .sy-caption-wrap .sy-caption a:visited {
    color: #e24b70;
    font-weight: 600;
    text-decoration: none
}

.sy-caption-wrap .sy-caption a:hover, .sy-caption-wrap .sy-caption a:focus {
    text-decoration: underline
}

@media only screen and (max-device-width: 600px), screen and (max-width: 600px) {
    .sy-caption-wrap {
        left: 0;
        bottom: 0.4em
    }

    .sy-caption-wrap .sy-caption {
        left: 0;
        padding: 0.2em 0.4em;
        font-size: 0.92em;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0
    }
}

.sy-pager {
    overflow: hidden;
    *zoom: 1;
    display: block;
    width: 100%;
    margin: 1em 0 0;
    padding: 0;
    list-style: none;
    text-align: center
}

.sy-pager li {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    margin: 0 1em 0 0;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.sy-pager li.sy-active a {
    background-color: #e24b70
}

.sy-pager li a {
    width: 100%;
    height: 100%;
    display: block;
    background-color: #ccc;
    text-indent: -9999px;
    -moz-background-size: 2em;
    -o-background-size: 2em;
    -webkit-background-size: 2em;
    background-size: 2em;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%
}

.sy-pager li a:link, .sy-pager li a:visited {
    opacity: 1.0
}

.sy-pager li a:hover, .sy-pager li a:focus {
    opacity: 0.6
}

.sy-filler {
    width: 100%
}

.sy-filler.ready {
    -moz-transition: padding 600ms ease;
    -o-transition: padding 600ms ease;
    -webkit-transition: padding 600ms ease;
    transition: padding 600ms ease
}

.restricted-width {
    margin-left: auto;
    margin-right: auto;
    padding: 0 2%
}

@media only screen and (min-width: 1180px) {
    .restricted-width {
        max-width: 1180px
    }
}


.thumb-box {
    padding: 1.4em 0 1em;
    margin-left: -1%;
    width: 102%
}

.thumb-box .thumbs {
    overflow: hidden;
    *zoom: 1
}

.thumb-box .thumbs li {
    float: left;
    width: 25%;
    text-align: center;
    padding:0 1% 1% 1%;
}

.thumb-box .thumbs li img {
    width: 100%;
    opacity: .8;
    -moz-transition: opacity 0.32s;
    -o-transition: opacity 0.32s;
    -webkit-transition: opacity 0.32s;
    transition: opacity 0.32s;
    border-bottom: 4px solid transparent
}

.thumb-box .thumbs li img.active {
    border-color: #31ACE2;
    opacity: 1
}

.thumb-box .thumbs li:hover img {
    opacity: 1;
    -moz-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s
}
