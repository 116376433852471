html {
    overflow-x: hidden;
}

body {
    padding-top: 106px;
    color: #9d9d9d;
    font-family: 'Open Sans', sans-serif;
    overflow-x: hidden;
    font-size: 1.45em;
}

body a {
    color: #3ab9e6;
}

body a:hover, body a:focus {
    color: #10617c;
    text-decoration: none;
}

h1, h2, h3, h4, h5 {
    color: #106492;
    font-size: 1.8em;
    font-weight: bold;
    margin-bottom: 20px;
}

img {
    max-width: 100%;
}

.padding {
    padding-top: 50px;
    padding-bottom: 50px;
}

.navbar-inverse {
    background-color: #fff;
    /*border-color: #fff;*/
    padding: 28px 0px;
    /*-webkit-box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.2);*/
    /*-moz-box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.2);*/
    /*box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.2);*/
    font-size: 0.95em;
}

.navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
    color: #3ab9e6;
}

.navbar-brand {
    float: left;
    height: 50px;
    padding: 15px;
    line-height: 31px;
    margin-right: 60px;
    margin-top: -4px;
    width: 250px;
}

.navbar-brand img {
    width: 100%;
}

.navbar-inverse .navbar-toggle {
    border-color: #3ab9e6;
    background-color: #3ab9e6;
}

.navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover {
    border-color: #d24c40;
    background-color: #d24c40;
    color: #fff;
}

.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a:hover {
    background: #3ab9e6;
    color: #fff;
}

.navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #3ab9e6;
}

.navbar .info {
    margin-left: 4%;
    margin-top: 20px;
    float: left;
    font-weight: normal;
}

.navbar .info i {
    float: left;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 6px;
    border: 2px solid #38b6e6;
    font-size: 1.1em;
    width: 25px;
    height: 25px;
    line-height: 23px;
    text-align: center;
    color: #38b6e6;
}

.navbar .info span {
    float: left;
    color: #38b6e6;
}

.navbar .info span em {
    font-style: normal;
}

.navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse {
    max-height: inherit;
}

.form-control {
    height: 42px;
    margin-bottom: 5px;
}

button.btn {
    height: 42px;
    font-size: 1.05em;
}

.home-search {
    background: red;
    width: 100%;
    padding: 105px 0px 100px 0px;
    background: url(../images/bg_search.jpg) no-repeat center -150px;
    background-size: cover;
    font-size: 1.0em;
}

.home-search .cities .btn {
    margin-bottom: 5px;
}

.steps {
    color: #0c5b86;
    font-size: 1.1em;
}

.steps h1, .spotlight h2 {
    margin-bottom: 60px;
}

.btn-kb {
    color: #fff;
    background-color: #0c6188;
    border-color: #0c6188;
}

.btn-kb:hover {
    color: #fff;
    background-color: #41b4e3;
    border-color: #41b4e3;
}

.btn-outline {
    color: #fff;
    background-color: transparent;
    border: 2px white solid;
}

.btn-outline:hover {
    color: #fff;
}

.btn-info {
    background: #dc493b;
}

strong.title {
    color: #ffffff;
    margin-bottom: 8px;
    display: block;
    padding-top: 15px;
}

.spotlight {
    background: #8dd4f2;
}

.spotlight #carousel {
    position: relative;
}

.spotlight #carousel .item {
    background: #fff;
    margin: 0px 5px;
}

.spotlight #carousel .item a {
    color: #dc493b;
}

.spotlight #carousel .item a:hover {
    color: #3ab9e6;
}

.spotlight #carousel .item img {
    width: 100%;
}

.spotlight #carousel .item span, #carousel .item strong, #carousel .item em {
    display: block;
}

.spotlight #carousel .item span {
    padding: 10px 15px;
}

.spotlight #carousel .item em {
    font-style: normal;
}

.spotlight #carousel .owl-controls {
    position: absolute;
    top: 35%;
    width: 100%;
}

.spotlight #carousel .owl-controls .owl-prev {
    left: -14%;
    position: absolute;
    background: none;
    font-size: 7.0em;
    line-height: 60px;
    font-family: 'Open Sans', sans-serif;
    color: #0b6085;
    font-weight: bold;
}

.spotlight #carousel .owl-controls .owl-next {
    right: -14%;
    position: absolute;
    background: none;
    font-size: 7.0em;
    line-height: 60px;
    font-family: 'Open Sans', sans-serif;
    color: #0b6085;
    font-weight: bold;
}

.alternative-logo {
}

.brands {
    margin-top: -40px;
    overflow: hidden;
}

.brands h1, .brands h2, .brands h3, .brands h4 {
    margin-bottom: 60px;
}

.brands #brands {
    position: relative;
}

.brands #brands .item {
    height: 74px;
    text-align: center;
}

.brands #brands .item img {
    height: 74px;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
    -webkit-transition: all .6s ease; /* Fade to color for Chrome and Safari */
    -webkit-backface-visibility: hidden; /* Fix for transition flickering */
}

.brands #brands .item img:hover {
    filter: none;
    -webkit-filter: grayscale(0%);
}

.quotes {
    border-top: 1px solid #919698;
}

.quotes i {
    color: #dcc73b;
    font-size: 2.4em;
    margin: 0px 5px;
}

.quotes .stars {
    margin-bottom: 10px;
}

.action {
    background: #d1dce0;
    color: #dc493b;
    font-size: 1.45em;
}

.action .btn-kb {
    font-size: 0.9em;
}

.sub {

    color: #fff;
    padding: 16px 0px 12px 0px;
    font-size: 0.95em;
    z-index: 100;
    position: relative;
}

.sub .breadcrumb {
    color: #fff;
    padding: 0px 0px;
    margin-bottom: 0px;
    list-style: none;
    background-color: transparent;
    border-radius: 0;
}

.sub .breadcrumb li a, .sub a {
    color: #fff;
}

.sub .breadcrumb li a:hover, .sub a:hover {
    color: #a6e5ff;
}

.sub .breadcrumb > li + li:before {
    padding: 0 5px;
    color: #fff;
    content: "\003e";
}

.sub i {
    font-size: 1.25em;
    margin-right: 5px;
}

.filters {
    padding: 25px 0px;
    width: 100%;
    display: none;
    position: absolute;
    background: #dc493b;
    z-index: 9;
    color: #fff;
    -webkit-box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.2);
}

.filters .checkbox, .filters .radio {
    margin-top: 0px;
    margin-bottom: 0px;
}

.filters .btn-outline {
    margin-top: 20px;
}

.btn-danger {
    background: #dc493b;
}

.maps {
}

.maps .desktop {
    display: block;
}

.maps .mobile {
    display: none;
}

.maps iframe {
    margin: 0;
    padding: 0;
}

.instructions {
    margin-top: -20px;
    margin-bottom: 20px;
    color: #dc493b;
}

.instructions span {
    display: inline-block;
    margin: 0px 10px;
}

.pagi {
    margin-bottom: 20px;
    line-height: 1.5em;
    color: #dc493b;
    background: #bcdae7;
    font-size: 1.1em;
    padding: 20px 18px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.pagi .pagination {
    margin: 0px;
    float: right;
}

.pagination > li > a, .pagination > li > span {
    border: 0;
    background: transparent;
    font-size: 1.0em;
    color: #dc493b;
    padding-top: 0px;
    padding-bottom: 0px;
}

.pagination > li > a:hover, .pagination > li > a.active, .pagination > li > a:focus, .pagination > li > span:hover, .pagination > li > span:focus {
    background: transparent;
    font-weight: bold;
}

.pagination > li:last-child > a, .pagination > li:last-child > span, .pagination > li:hover:last-child > a, .pagination > li:hover:last-child > span {
    background: transparent;
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:hover:first-child > a, .pagination > li:hover:first-child > span {
    background: transparent;
}

.pagination > li.active span, .pagination > li:hover.active span {
    background: transparent;
    font-weight: bold;
    color: #dc493b
}

.objects {
    margin-bottom: 80px;
}

.objects .object {
    /*border: 1px solid #eaeef0;*/
    margin-bottom: 15px;
}

.objects .object .object-padding {
    padding: 48px 0px 0px 0px;
    text-align: center;
}

.objects .object .object-padding .btn {
    margin-top: 26px;
}

.objects .object img {
    width: 100%;
}

.objects .object h2 {
    margin-top: 10px;
}

.objects .object h2 a {
    color: #dc493b;
    font-weight: normal;
    text-decoration: underline;
    font-size: 0.75em;
    padding-top: 0px;
}

.objects .object ul.facilities, .page .content ul.facilities {
    overflow: hidden;
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.objects .object ul.facilities li, .page .content ul.facilities li {
    float: left;
    width: 33%;
    font-size: 13px;
    padding: 0;
}

.objects .object ul.facilities li i.fa, .page .content ul.facilities li i.fa {
    color: #58c2ea;
    width: 16px;
}

.page {
    padding-bottom: 80px;
    padding-top: 36px;
}

.page h1 {
    margin-top: 0px;
}

.page .info {
    font-size: 1.5em;
    color: #dc493b;
    margin-bottom: 15px;
}

.page .info small {
    font-size: 0.8em;
    display: inline-block;
    margin-left: 20px;
}

.page .info small i {
    margin-left: 0px;
    color: #ee5b42;
    font-size: 1.3em;
}

.page .btn-outline {
    border-color: #176b8e;
    color: #176b8e;
    margin-right: 4px;
}

.page .buttons {
    margin-bottom: 25px;
}

.page .content {
    padding-bottom: 25px;
}

.page .slider {
    padding-bottom: 20px;
}

.page .share span {
    float: left;
    line-height: 38px;
    color: #dc493b;
    margin-right: 10px;
    display: block;
}

.page .slider ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.page h2, .page h3, .page h4, .page h5, .page h6 {
    color: #dc493b;
}

.page .request {
    background: #d8eef7;
    margin-bottom: 20px;
}

.page .request .form-control {
    border: 0px;
}

.page .sidebar-padding {
    padding: 30px;
}

.page .request, .page .appointment {
    color: #000;
}

.page .request h3, .page .appointment h3 {
    color: #dc493b;
    font-size: 1.6em;
    margin-bottom: 20px;
    overflow: hidden;
    margin-top: 0px;
}

.page .request h3 i, .page .appointment h3 i {
    font-size: 2.0em;
    float: left;
    display: block;
    width: 20%;
}

.page .request h3 span, .page .appointment h3 span {
    float: left;
    width: 80%;
    margin: 0px;
    padding: 0px;
}

.page .request label, .page .appointment label {
    font-weight: normal;
}

.page .appointment {
    background: #fff;
    /*border: 2px solid #d8eef7;*/
    margin-bottom: 20px;
}

.page .appointment em {
    display: block;
    font-size: 1.2em;
    margin-top: 20px;
    font-style: normal;
}

.page .blogitem {
    border-bottom: 1px solid #919698;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.page .blogitem .image {
    margin-bottom: 10px;
}

.page .blogitem .image img {
    width: 100%;
}

.page .blogitem .title {
    overflow: hidden;
    margin-bottom: 15px;
}

.page .blogitem .title .date {
    float: left;
    margin-right: 15px;
    text-align: center;
    display: inline-block;
    font-weight: bold;
    background: #dc493b;
    padding: 8px 15px;
    color: #fff;
    line-height: 1.8em;
}

.page .blogitem .title .date span {
    display: block;
    font-size: 2.0em;
}

.page .blogitem .title h2 {
    min-height: 70px;
    display: inline-block;
    line-height: 1.0em;
    margin-top: 0px;
    margin-bottom: 0px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-line-pack: center;
    -ms-flex-line-pack: center;
    -webkit-align-content: center;
    align-content: center;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.page .blogitem a.readmore {
    color: #dc493b;
    text-decoration: underline;
}

.page .blogitem .tagshare {
    overflow: hidden;
    color: #dc493b;
}

.page .blogitem .tagshare a {
    color: #dc493b;
}

.page .blogitem .tagshare .tags {
    float: left;
    width: 60%;
    padding-top: 8px;
}

.page .blogitem .tagshare .share {
    text-align: right;
    width: 40%;
    float: right;
}

.page .category {
    padding-top: 45px;
}

.page .category h3 {
    padding-top: 0px;
    margin: 0px;
    font-weight: normal;
    color: #dc493b;
    padding-bottom: 15px;
}

.page .category ul {
    list-style: none;
    padding: 0px;
}

.page .category ul li a span {
    text-decoration: underline;
    color: #dc493b;
}

.page .category ul li a {
    color: #808080;
    text-decoration: none;
}

.page .form span i {
    float: left;
    position: relative;
    width: 30px;
    font-size: 2.0em;
    display: inline-block;
    margin-right: 10px;
}

.page .form span em {
    float: left;
    position: relative;
    margin-top: 5px;
    display: inline-block;
    font-style: normal;
    font-weight: normal;
}

.page .form h2 {
    margin-top: 35px;
}

footer {
    background: #dc493b;
    color: #fff;
}

footer h1, footer h2, footer h3, footer h4 {
    color: #fff;
}

footer a {
    color: #fff;
    text-decoration: underline;
}

footer a:hover {
    color: #ffbab3;
}

footer em {
    font-style: normal;
    padding-right: 20px;
    display: inline-block;
}

footer ul {
    display: inline-block;
    list-style: none;
    margin: 0px;
    padding: 0px;
}

footer ul li {
    display: inline-block;
    padding-right: 20px;
}

footer .sitemap {
    padding-top: 60px;
}

footer .sitemap i {
    font-size: 3.0em;
    margin-top: -7px;
    margin-bottom: 15px;
}

footer form {
    margin-bottom: 10px;
}

#map {
    width: 100%;
    height: 400px;
}

#map .object-img {
    width: 240px;
}

/********* BIG SCREENS ********/
@media (max-width: 1199px) {
    .padding {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .navbar-brand {
        margin-right: 10px;
    }

    .navbar .info {
        margin-left: 3%;
        margin-top: 16px;
    }

    .navbar .info span em {
        display: none;
    }

    .navbar .info i {
        margin-top: -2px;
    }

    .home-search {
        padding: 85px 0px 80px 0px;
    }

    .objects .object ul.facilities li, .page .content ul.facilities li {
        float: none;
        width: 100%;
        padding: 0;
    }
}

/********* SMALL SCREENS ********/
@media (max-width: 991px) {
    .padding {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .navbar-brand {
        margin-right: 10px;
    }

    .navbar .info {
        display: none;
    }

    .home-search {
        padding: 65px 0px 60px 0px;
    }

    .pagination > li > a, .pagination > li > span {
        padding: 0px 10px;
    }

    footer .sitemap {
        padding-top: 40px;
    }

    .nav > li > a {
        padding: 10px 10px;
    }
}

/********* MOBILE SCREENS ********/
@media (max-width: 767px) {
    body {
        padding-top: 50px;
    }

    .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
        margin-bottom: 20px;
    }

    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
        margin-bottom: 20px;
    }

    .sub .col-sm-7, .sub .col-sm-5, .sub .col-md-8, .sub .col-md-4 {
        margin-bottom: 0px;
    }

    .navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
        border: none;
    }

    .navbar-brand {
        padding: 15px;
    }

    .navbar-inverse {
        padding: 0px 0px;
    }

    .navbar-brand {
        margin-right: 0px;
        margin-top: 2px;
    }

    .navbar .info {
        display: block;
        float: none;
        overflow: hidden;
        margin-left: 0px;
    }

    .navbar .info i {
        margin-top: 0px;
    }

    .navbar .info span {
        line-height: 27px;
    }

    .home-search {
        padding: 60px 0px 60px 0px;
    }

    .maps .desktop {
        display: none;
    }

    .maps .mobile {
        display: block;
    }

    .instructions span {
        display: block;
        margin: 0px 0px 10px 0px;
    }

    .pagi {
        text-align: center;
    }

    .pagi .pagination {
        float: none;
        margin-bottom: -20px;
    }

    .objects .object .object-padding {
        padding: 0px 20px;
        text-align: left;
        margin-top: 20px;
    }

    .objects .object .object-padding .btn {
        margin-top: 10px;
    }

    .objects .object .object-text {
        padding: 0px 20px;
    }

    .objects .object ul li {
        float: none;
        width: 100% !important;
    }

    .p-3 {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .page .blogitem .tagshare .tags {
        float: none;
        width: 100%;
    }

    .page .blogitem .tagshare .share {
        float: none;
        width: 100%;
        text-align: left;
    }

    footer .sitemap {
        padding-top: 20px;
    }

    footer ul.list li, footer em {
        display: block;
    }

    footer ul.list {
        margin-bottom: 20px;
    }

    #map {
        height: 300px;
    }

    #map .object-img {
        width: 150px;
    }

    #map .object-info {
        display: none;
    }
}

@media (max-width: 480px) {
    .navbar-brand {
        margin-top: 2px;
        width: 200px;
    }

    .page .share span {
        float: none;
    }

    .page .info small {
        display: block;
        margin-left: 0px;
    }
}

.share {
    min-height: 45px;
}

.dropzone {
    border: 2px dashed #CCCCCC;
    border-radius: 5px;
    background: white;
}

.required-mark {
    color: #dc493b;
    padding-left: 5px;
}

.thumb {
    width: 150px;
    height: 100px;
    background-size: cover;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
}

.thumb-delete {
    position: absolute;
    right: 5px;
    top: 5px;
}

span.twitter-typeahead .tt-menu,
span.twitter-typeahead .tt-dropdown-menu {
    /*position: absolute;*/
    /*top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: -2px 0 0;
    list-style: none;
    background-clip: padding-box;
    font-size: 14px;
    text-align: left;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);*/
}

span.twitter-typeahead .tt-suggestion {
    display: block;
    padding: 3px 8px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333333;
    white-space: nowrap;
}

span.twitter-typeahead .tt-suggestion.tt-cursor,
span.twitter-typeahead .tt-suggestion:hover,
span.twitter-typeahead .tt-suggestion:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    background-color: #ff6f61;
}

.input-group.input-group-lg span.twitter-typeahead .form-control {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;

}

.input-group.input-group-sm span.twitter-typeahead .form-control {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

span.twitter-typeahead {
    width: 100%;
}

.input-group span.twitter-typeahead {
    display: block !important;
    height: 34px;
}

.input-group span.twitter-typeahead .tt-menu,
.input-group span.twitter-typeahead .tt-dropdown-menu {
    top: 32px !important;
}

.input-group span.twitter-typeahead:not(:first-child):not(:last-child) .form-control {
    border-radius: 0;
}

.input-group span.twitter-typeahead:first-child .form-control {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group span.twitter-typeahead:last-child .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.input-group.input-group-sm span.twitter-typeahead {
    height: 30px;
}

.input-group.input-group-sm span.twitter-typeahead .tt-menu,
.input-group.input-group-sm span.twitter-typeahead .tt-dropdown-menu {
    top: 30px !important;
}

.input-group.input-group-lg span.twitter-typeahead {
    height: 46px;
}

.input-group.input-group-lg span.twitter-typeahead .tt-menu,
.input-group.input-group-lg span.twitter-typeahead .tt-dropdown-menu {
    top: 46px !important;
}

.objects .object ul {
    padding-bottom: 20px;
}

.map-container {
    overflow: hidden;
}

.object-column {
    overflow-y: auto;
    max-height: 100%;
}

@media (max-width: 991px) {
    .object-column {
        overflow-y: auto;
        max-height: none;
    }
}

.new-filters {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #ddd;
}

.filter-dropdown {
    position: relative;
    display: inline-block;
    padding: 10px;
    border-right: 1px solid #ddd;
    color: #106492;
}

@media (max-width: 631px) {
    .filter-dropdown {
        padding: 6px;
        width: 100%;
        border-bottom: 1px solid #7bacb4;
        margin-left: 0;
    }

    .bg-info {
        width: 99%;
        margin-left: 0;
        float: left !important;
    }
}

.filter-dropdown:hover {
    cursor: pointer;
}

.filter-dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 220px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 10px;
    z-index: 1;
}

.right-item {
    margin-right: -15px;
}

.filter-dropdown-content .radio {
    padding-top: 3px;
}

.filter-dropdown-content .checkbox {
    padding-top: 3px;
}

.filter-dropdown:hover .filter-dropdown-content {
    display: block;
}

.no-padding {
    padding: 0;
}

.p-3 {
    padding-left: 30px;
    padding-right: 30px;
}

.pt-3 {
    padding-top: 20px;
}

.pl-2 {
    padding-left: 22px;
}

.color-black {
    color: #dc493b !important;
}

.feature-icon {
    color: #58c2ea;
    font-size: 16pt;
    padding-left: 5px;
    padding-right: 5px;
    cursor: help;
}

.header-search {
    margin-bottom: 0;
    height: 38px;
}

.filter-button {
    height: 32px !important;
    font-size: 10pt !important;
    margin-top: 10px;
}

@media (min-width: 1199px)  and (max-width: 1544px) {
    .more-info-button {
        /*margin-left: -40px !important;*/
    }
}

@media (max-width: 767px) {
    .filter-row {
        padding-left: 0px;
        padding-right: 0px;
    }

    .pl-2 {
        padding: 5px 46px;
    }

    .more-info-button {
        /*margin-left: 20px;*/
    }

    .sub {
        margin-top: 64px;
    }

    .mobile-search-container .form-group {
        margin-bottom: 0;
    }

    .filter-dropdown {
        padding: 12px;
    }

    .filterForm {
        min-height: 300px;
    }

    .filter-dropdown-content {
        padding-right: 25px;
    }

    #theHomeSearch {
        padding-top: 30px;
    }

    .general-container .col-md-7 {
        overflow-x: hidden;
        width: 100%;
    }

    .thumbs {
        padding-left: 0px;
    }

    .pagi {
        padding: 6px 2px;
    }

    .first-pagination-row {
        margin-bottom: 0 !important;
    }

    .second-pagination-row {
        margin-top: -10px !important;
        margin-bottom: 10px !important;
    }
}

@media (max-width: 1200px) {
    .filter-dropdown {
        font-size: 12px;
    }

    @media (max-width: 1300px) {
        .home-search {
            background-size: auto;
        }
    }

    @media (max-width: 1555px) {
        .more-info-button {
            /*margin-right: 20px;*/
            /*font-size: 12px;*/
        }
    }

    .info .input-group span {
        float: none;
    }

    .info .input-group .btn {
        font-size: 10pt;
        height: auto;
    }

    .search-button i {
        color: #fff !important;
        border: none !important;
        width: auto !important;
        height: auto !important;
        margin: 0 0 !important;
    }

    #photos2 li {
        list-style: none;
    }

    .break-out {
        width: 100vw;
        position: relative;
        left: calc(-1 * (100vw - 100%) / 2);
    }

    .alternatemap {
        min-height: 500px;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .full-width {
        width: 100%;
        margin: 0;
    }
}

.rm-link {
    margin-left: 20px;
    text-decoration: underline;
    color: #dc493b;
}